.announcement-grid-section-shadow {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 54px;
  padding-bottom: 54px;

  .grid-section {
    display: grid;
    gap: 34px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  
  @media (min-width: 1024px) {
    .grid-section {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  
  .section-title {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    height: 2rem;
    width: 16rem;
    border-radius: 0.375rem;
    background-color: var(--bg-1);
  }
  
  .card {
    overflow: hidden;
    border-radius: 24px;
    background-color: var(--bg-1);

    .image {
      height: 370px;
      width: 100%;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      background-color: var(--bg-2);
    }

    .content {
      position: relative;
      z-index: 10;
      margin-top: -5rem;
      height: 100%;
      --tw-space-y-reverse: 0;
      margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
      margin-bottom: calc(8px * var(--tw-space-y-reverse));
      border-radius: 24px;
      background-color: var(--bg-1);
      padding: 34px;

      .date {
        height: 1rem;
        width: 8rem;
        border-radius: 0.125rem;
        background-color: var(--bg-2);
      }

      .title {
        height: 26px;
        width: 90%;
        border-radius: 0.375rem;
        background-color: var(--bg-2);
        text-decoration-line: underline;
        text-decoration-line: underline;
      }

      .headline {
        height: 16px;
        overflow: auto;
        border-radius: 0.375rem;
        background-color: var(--bg-2);
      }
    }
  }
}

@media (min-width: 640px) {
  .announcement-grid-section-shadow, .announcement-grid-section {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .announcement-grid-section-shadow, .announcement-grid-section {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .announcement-grid-section-shadow, .announcement-grid-section {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .announcement-grid-section-shadow, .announcement-grid-section {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .announcement-grid-section-shadow, .announcement-grid-section {
    max-width: 1536px;
  }
}

.announcement-grid-section {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 54px;
  padding-bottom: 54px;

  .grid-section {
    display: grid;
    gap: 34px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  
  @media (min-width: 1024px) {
    .grid-section {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  
  .section-title {
    margin-bottom: 24px;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    color: rgb(33 33 33);
  }
  
  .card {
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    background-color: rgb(248 248 248 );
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    .pdf-wrapper {
      display: flex;
      height: 333px;
      align-items: center;
      justify-content: center;

      .pdf-frame {
        height: 242px;
        width: 154px;
      }
    }

    .content {
      flex: 1 1 0%;
      border-radius: 24px;
      --tw-bg-opacity: 1;
      background-color: rgb(255 255 255 / var(--tw-bg-opacity));
      padding: 34px;
      font-size: 14px;
      font-weight: 400;
      --tw-text-opacity: 1;
      color: rgb(33 33 33 / var(--tw-text-opacity));

      .main-content {
        margin-bottom: 29px; 

        .label {
          color: #999999;
          font-weight: 500;
          font-size: 14px;
        }

        .title {
          color: #999999;
          font-weight: 700;
          font-size: 24px;
          margin-top: 8px;
          margin-bottom: 8px;

          &:hover {
            text-decoration: underline;
          }
        }

        p {
          max-height: 105px;
          overflow: auto;
        }
      }

      .secondary-content {
        display: flex;
        align-items: center;
        --tw-space-x-reverse: 0;
        margin-right: calc(8px * var(--tw-space-x-reverse));
        margin-left: calc(8px * calc(1 - var(--tw-space-x-reverse)));
        border-radius: 8px;
        border-width: 1px;
        --tw-border-opacity: 1;
        border-color: rgb(222 222 222 / var(--tw-border-opacity));
        padding: 16px;

        .detail {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        
        svg {
          flex-shrink: 0;
        }
        
        .download-button {
          color: #DB1A22;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }
}