.operational-spot {
  container: mx-auto;
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
  gap: 34px;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;

  @include breakpoint(lg) {
    flex-direction: row;
  }

  .item-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-size: 36px;
      font-weight: bold;
    }

    .desc {
      font-size: 16px;
      margin-top: 24px;
    }

    .grid-items {
      display: flex;
      overflow: auto;
      margin-top: 54px;

      @include breakpoint(md) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
      }

      .grid-item {
        width: 200px;
        flex-shrink: 0;
        background-color: white;
        padding: 20px 24px;
        border-radius: 12px;
        display: flex;
        gap: 12px;
        align-items: center;
        color: #999999;
        cursor: pointer;
        flex-direction: column;

        @include breakpoint(md) {
          width: 100%;
          flex-direction: row;
        }

        svg {
          flex-shrink: 0;
        }

        &.active {
          background-color: #0664B4;
          color: white;
          
          svg path {
            fill: white;
          }
        }
      }
    }
  }

  .item-right {
    flex: 1;
    height: 660px;

    img {
      margin-left: auto;
      margin-right: auto;
      border-radius: 24px;
      object-fit: contain;

      @include breakpoint(lg) {
        height: 660px;
      }
    }
  }
}

.operational-spot-shadow {
  container: mx-auto;
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
  gap: 34px;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;

  @include breakpoint(lg) {
    flex-direction: row;
  }

  .item-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      height: 36px;
      width: 60%;
      border-radius: 0.125rem;
      background-color: var(--bg-1);
    }

    .desc {
      height: 16px;
      width: 40%;
      margin-top: 24px;
      border-radius: 0.125rem;
      background-color: var(--bg-1);
    }

    .grid-items {
      display: flex;
      overflow: auto;
      margin-top: 54px;

      @include breakpoint(md) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
      }

      .grid-item {
        width: 200px;
        flex-shrink: 0;
        background-color: var(--bg-1);
        padding: 20px 24px;
        border-radius: 12px;
        display: flex;
        gap: 12px;
        align-items: center;
        color: #999999;
        cursor: pointer;
        flex-direction: column;

        @include breakpoint(md) {
          width: 100%;
          flex-direction: row;
        }

        p {
          display: block;
          width: 60%;
          height: 14px;
          background-color: var(--bg-2);
        }
        
        svg {
          flex-shrink: 0;

          path {
            background-color: var(--bg-2);
          }
        }
      }
    }
  }

  .item-right {
    flex: 1;
    height: 660px;

    #operational-spot-id {
      width: 100%;
      height: 660px;
      background-color: var(--bg-1);
      margin-left: auto;
      margin-right: auto;
      border-radius: 24px;
      object-fit: contain;
    }
  }
}