.logo-slider-shadow {
  &.root {
    padding: 54px 0;
    background-color: var(--bg-1);
  }

  .wrapper {
    @extend .my-container;
  }

  .main-title {
    border-radius: 0.375rem;
    height: 36px;
    background-color: var(--bg-2);
    width: 100%;
    max-width: 300px;
    line-height: 43.2px;
    margin: 0 auto 34px auto;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 583px;
    border-radius: 24px;
    border: 1px solid var(--bg-2);
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  .content-data {
    width: 100%;
    transition: all .3s ease-in-out;
    flex: 1 0 100%;
  }

  .image-rail, .controller {
    flex: 0 0 50%;
  }

  .image-rail {
    display: flex;
    width: 100%;
    overflow: hidden;
  }

  .image-data {
    transition: all .3s ease-in-out;
    display: flex;
    flex: 0 0 100%;
    padding: 34px 0;
  }

  .image {
    border-radius: 0.5rem;
    height: 255px;
    width: 100%;
    flex: 0 0 100%;
    object-fit: contain;
    background-color: var(--bg-2);
  }

  .controller {
    padding: 34px;

    .controller-content {
      overflow: hidden;
      transition: all .3s ease-in-out;
    }
  }

  .content-rail {
    margin-bottom: 54px;
    display: flex;
    transition: all .3s ease-in-out;
  }

  .content-title {
    border-radius: 0.375rem;
    height: 36px;
    max-width: 300px;
    width: 100%;
    line-height: 43.57px;
    background-color: var(--bg-2);
    margin-bottom: 24px;
  }

  .content-desc {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
  }

  .controller-button {
    display: flex;
    gap: 16px;
    justify-content: space-between;

    @include breakpoint(md) {
      justify-content: flex-start;
    }
  }
  
  .button {
    cursor: pointer;
    width: 54px;
    height: 54px;
    border-radius: 54px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.left {
      border: 1px solid var(--bg-2);
    }

    &.right {
      background-color: var(--bg-2);
    }
  }
}

.logo-slider {
  &.root {
    padding: 54px 0;
    background-color: white;
  }

  .wrapper {
    @extend .my-container;
  }

  .main-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 43.2px;
    text-align: center;
    margin-bottom: 34px;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 583px;
    border-radius: 24px;
    border: 1px solid #DDD;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  .content-data {
    width: 100%;
    transition: all .3s ease-in-out;
    flex: 1 0 100%;
  }

  .image-rail, .controller {
    flex: 0 0 50%;
  }

  .image-rail {
    display: flex;
    width: 100%;
    overflow: hidden;
  }

  .image-data {
    transition: all .3s ease-in-out;
    display: flex;
    flex: 0 0 100%;
    padding: 34px 0;
  }

  .image {
    height: 255px;
    width: 100%;
    flex: 0 0 100%;
    object-fit: contain;
  }

  .controller {
    padding: 34px;

    .controller-content {
      overflow: hidden;
      transition: all .3s ease-in-out;
    }
  }

  .content-rail {
    margin-bottom: 54px;
    display: flex;
    transition: all .3s ease-in-out;
  }

  .content-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 43.57px;
    text-align: left;
    color: var(--primary-color);
    margin-bottom: 24px;
  }

  .content-desc {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
  }

  .controller-button {
    display: flex;
    gap: 16px;
    justify-content: space-between;

    @include breakpoint(md) {
      justify-content: flex-start;
    }
  }
  
  .button {
    cursor: pointer;
    width: 54px;
    height: 54px;
    border-radius: 54px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.left {
      border: 1px solid #999;
    }

    &.right {
      background-color: var(--primary-color);
    }
  }
}