.header {
  &.root {
    height: 100vh;
    width: 100vw;
    background-position: center, center;
    background-size: cover, cover;
    position: relative;
  }

  .content {
    height: 100%;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    .subtitle {
      border: 1px solid white;
      color: white;
      padding: 8px 16px;
      border-radius: 100px;
    }
  
    .title {
      font-weight: 700;
      color: white;
      font-size: 48px;
    }
  }

  .bubble {
    position: absolute;
    bottom: 55px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: white;
    width: 64px;
    height: 64px;
    border-radius: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
} 

.header-shadow {
  &.root {
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-size: cover;
    position: relative;
    background-color: var(--bg-1);
  }

  .content {
    height: 100%;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    .subtitle {
      border: 1px solid var(--bg-2);
      padding: 8px 16px;
      border-radius: 100px;

      div {
        background-color: var(--bg-2);
        border-radius: 5px;
        width: 100px;
        height: 20px;
      }
    }

    .title {
      font-weight: 700;
      max-width: 700px;
      width: 100%;
      background-color: var(--bg-2);
      height: 48px;
      border-radius: 5px;
    }
  }

  .bubble {
    position: absolute;
    bottom: 55px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: var(--bg-2);
    width: 64px;
    height: 64px;
    border-radius: 64px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg path {
      fill: var(--bg-3);
    }
  }
} 