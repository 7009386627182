.team-slider-shadow {
  &.root {
    .my-container {
      display: flex;
      flex-direction: column;
      gap: 34px;
      padding: 54px 16px;
      flex-direction: column;

      @include breakpoint(xl) {
        flex-direction: row;
      }
    }
    background-color: var(--bg-1);
  }

  .slider-rail {
    flex: 2;
    
    .profile-card {
      background-color: var(--bg-2);
      border-radius: 24px;
      display: flex;
      height: 660px;
      flex-direction: column;

      @include breakpoint(md) {
        flex-direction: row;
      }
    }

    .profile-user {
      background-color: var(--bg-3);
      flex: 0 0 50%;
      border-top-right-radius: 24px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 24px;

      @include breakpoint(md) {
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
      }

      .person-image {
        width: 250px;
        height: 250px;
        background-color: var(--bg-4);
        border-radius: 250px;
        object-fit: cover;
        flex-shrink: 0;
        margin-bottom: 34px;
      }

      .person-name {
        border-radius: 0.375rem;
        height: 24px;
        width: 80%;
        background-color: var(--bg-4);
        line-height: 29.05px;
        margin-bottom: 8px;
      }

      .person-position {
        border-radius: 0.375rem;
        height: 16px;
        background-color: var(--bg-4);
        width: 90%;
        line-height: 19.36px;
      }
    }


    .profile-bio {
      z-index: 2;
      flex: 0 0 50%;
      padding: 34px;
      font-size: 14px;
      overflow: auto;
    }
  }

  .slider-controller {
    flex: 1;
    display: flex;
    flex-direction: column;

    .avatar-grid-container {
      flex: 1;
    }

    .slider-title {
      border-radius: 0.375rem;
      height: 36px;
      width: 70%;
      background-color: var(--bg-2);
      margin-bottom: 24px;
    }

    .avatar-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
      
      .avatar {
        width: 112px;
        height: 112px;
        background-color: var(--bg-2);
        border-radius: 112px;
        filter: grayscale(1);
        flex-shrink: 0;
        margin: 0 auto;
        object-fit: cover;
        flex-grow: 0;
  
        &.active {
          filter: grayscale(0);
        }
      }
    }

    .navigator {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .indicators {
        display: flex;
        gap: 8px;
        align-items: center;
        
        div {
          width: 10px;
          height: 10px;
          background-color: #212121;
          border-radius: 10px;
          opacity: .35;

          &.active {
            opacity: 1;
          }
        }
      }
      
      .action-button {
        display: flex;
        gap: 16px;
      }

      .button {
        width: 54px;
        height: 54px;
        border-radius: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #DEDEDE;

        &.right {
          background-color: var(--primary-color);
        }
      }
    }
  }

}

.team-slider {
  &.root {
    display: flex;
    gap: 34px;
    padding: 54px 16px;
    flex-direction: column;

    @include breakpoint(xl) {
      flex-direction: row;
    }
  }

  .slider-rail {
    flex: 2;
    
    .profile-card {
      background-color: white;
      border-radius: 24px;
      display: flex;
      height: 660px;
      flex-direction: column;

      @include breakpoint(md) {
        flex-direction: row;
      }
    }

    .profile-user {
      background-color: var(--primary-color);
      flex: 0 0 50%;
      border-top-right-radius: 24px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 24px;

      @include breakpoint(md) {
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
      }

      .person-image {
        width: 250px;
        height: 250px;
        border-radius: 250px;
        object-fit: cover;
        flex-shrink: 0;
        margin-bottom: 34px;
      }

      .person-name {
        font-size: 24px;
        font-weight: 700;
        line-height: 29.05px;
        text-align: left;
        margin-bottom: 8px;
      }

      .person-position {
        font-size: 16px;
        font-weight: 500;
        line-height: 19.36px;
        text-align: left;
      }
    }


    .profile-bio {
      z-index: 2;
      flex: 0 0 50%;
      padding: 34px;
      font-size: 14px;
      overflow: auto;
    }
  }

  .slider-controller {
    flex: 1;
    display: flex;
    flex-direction: column;

    @include breakpoint(xl) {
      height: 656px;
    }

    .avatar-grid-container {
      flex: 1;
      overflow: auto;
    }

    .slider-title {
      font-family: Inter;
      font-size: 36px;
      font-weight: 700;
      line-height: 43.57px;
      text-align: left;
      margin-bottom: 24px;
    }

    .avatar-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }

    .av-sec {
      h3 {
        text-align: center;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
      }

      span {
        font-size: 14px;
        text-align: center;
        display: block;
        margin-bottom: 14px;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
      }
      
      .avatar {
        width: 112px;
        height: 112px;
        border-radius: 112px;
        filter: grayscale(1);
        flex-shrink: 0;
        margin: 0 auto;
        object-fit: cover;
        flex-grow: 0;
  
        &.active {
          filter: grayscale(0);
        }
      }
    }


    .navigator {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .indicators {
        display: flex;
        gap: 8px;
        align-items: center;
        
        div {
          width: 10px;
          height: 10px;
          background-color: #212121;
          border-radius: 10px;
          opacity: .35;

          &.active {
            opacity: 1;
          }
        }
      }
      
      .action-button {
        display: flex;
        gap: 16px;
      }

      .button {
        width: 54px;
        height: 54px;
        border-radius: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #DEDEDE;

        &.right {
          background-color: var(--primary-color);
        }
      }
    }
  }

}