
.card1x2-small-shadow {
  &.root {
    background-color: var(--bg-1);
  }

  .container {
    @extend .my-container;
    padding: 54px 16px;
  }

  .title {
    border-radius: 4px;
    width: 100%;
    background-color: var(--bg-2);
    margin: 0 auto 34px auto;
    max-width: 300px;
    height: 36px;
  }

  .row {
    display: flex;
    gap: 34px;
    flex-direction: column;
    flex: 1;
    height: 724px;
    
    @media (min-width: 768px) {
      flex-direction: row;
      height: auto;
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 345px;
      border-radius: 16px;
      flex: 1;
      background-color: var(--bg-2);
      position: relative;
      overflow: hidden;

      .image {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 345px;
        width: 100%;
        transform: translate(-50%, -50%);
        object-position: center;
        object-fit: cover;
        z-index: 1;
      }

      .content {
        padding: 34px;
        position: absolute;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 345px;
        
        .card-title {
          border-radius: 4px;
          background-color: var(--bg-3);
          width: 200px;
          height: 36px;
          margin-bottom: 34px;
        }
        
        .hyperlink {
          display: flex;
          align-items: center;
          font-weight: 700;
          color: white;
          gap: 8px;
          width: fit-content;
          cursor: pointer;
  
          .hyperlink-text {
            border-radius: 4px;
            height: 16px;
            width: 150px;
            background-color: var(--bg-3);
          }
          
          svg {
            transition: all 0.3s ease-in-out;
            path {
              fill: var(--bg-3);
            }
          }
          
          &:hover {
            svg {
              transform: translate(16px, 0);
            }
          }
        }
      }
    }
  }
}

.card1x2-small {
  &.root {
    background-color: white;
  }

  .container {
    @extend .my-container;
    padding: 54px 16px;
  }

  .title {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 34px;
    text-align: center;
  }

  .row {
    display: flex;
    gap: 34px;
    flex-direction: column;
    flex: 1;
    height: 724px;
    
    @media (min-width: 768px) {
      flex-direction: row;
      height: auto;
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 345px;
      border-radius: 16px;
      flex: 1;
      position: relative;
      overflow: hidden;

      &:hover .image {
        transform: scale(1.25);
      }
      
      .image {
        transition: all 0.3s ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
        height: 345px;
        width: 100%;
        filter: brightness(0.7);
        object-position: center;
        object-fit: cover;
        z-index: 1;
      }

      .content {
        padding: 34px;
        position: absolute;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 345px;
        
        .card-title {
          color: white;
          font-weight: 700;
          font-size: 36px;
          margin-bottom: 34px;
          text-align: center;
        }
        
        .hyperlink {
          display: flex;
          align-items: center;
          font-weight: 700;
          color: white;
          gap: 8px;
          width: fit-content;
          cursor: pointer;
  
          svg {
            transition: all 0.3s ease-in-out;
          }
          
          &:hover {
            svg {
              transform: translate(16px, 0);
            }
          }
        }
      }
    }
  }
}