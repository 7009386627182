.article.root {
  /* Styles for .article.root can be added here */
}

.article .image {
  height: 330px;
  width: 100%;
  border-radius: 24px 24px 0 0;
}

.article .content {
  z-index: 1;
  position: relative;
  border-radius: 24px;
  margin-top: -40px;
  background-color: white;
  padding: 34px;
  color: #212121;
  display: flex;
  flex-direction: column;
}

.article .content .date {
  color: #999999;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

.article .content .date .dot {
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #999999;
}

.article .content .title {
  font-size: 24px;
  font-weight: 700;
  margin-top: 8px;
  max-height: 75px;
  overflow: hidden;
}

.article .content .description {
  font-size: 14px;
  margin-top: 8px;
  max-height: 105px;
  overflow: hidden;
}
