.latest-article-page {
  &.root {
    padding: 64px 20px;
  }

  * {
    box-sizing: border-box;
  }

  .item-wrapper {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    gap: 35px;
    flex-direction: column;

    @include breakpoint(lg) {
      flex-direction: row;
    }
  }

  .item {
    height: 600px;
    flex: 1;

    &.item-article {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }

  .page-card {
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    height: 600px;
    
    .img {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(0.8);
    }

    .page-content {
      padding: 34px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;

      .title {
        font-size: 36px;
        color: white;
        font-weight: 700;
      }

      .desc {
        font-size: 16px;
        color: white;
        margin-top: 16px;
      }

      .next-wrapper {
        display: flex;
        gap: 16px;
        align-items: center;
        margin-top: 41px;
        &:hover {
          .next {
            text-decoration: underline;
          }
        }

        .next {
          color: white;
          font-weight: 700;
          font-size: 16px;
        }
      }
    }
  }

  .article-card {
    display: flex;
    border-radius: 12px;
    background-color: white;
    overflow: hidden;
    cursor: pointer;
    
    &:hover {
      .title {
        text-decoration: underline;
      }
    }

    .img {
      width: 180px;
      height: 180px;
      flex-shrink: 0;
    }

    .article-content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 16px 14px;

      .datetime {
        display: flex;
        gap: 8px;
        font-size: 12px;
        color: #999999;
        align-items: center;

        .dot {
          width: 4px;
          height: 4px;
          border-radius: 4px;
          background-color: #999999;
        }
      }

      .title {
        font-weight: 700;
        font-size: 14px;
        height: 60px;
        overflow: hidden;
        margin-top: 8px;
      }
    }
  }

  .page {
    // Empty selector, can be removed if not needed
  }
}

.latest-article-page-shadow {
  &.root {
    padding: 64px 0;
  }

  * {
    box-sizing: border-box;
  }

  .item-wrapper {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    gap: 35px;
    flex-direction: column;

    @include breakpoint(lg) {
      flex-direction: row;
    }
  }

  .item {
    height: 600px;
    flex: 1;

    &.item-article {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }

  .page-card {
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    height: 600px;
    
    .img {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(0.8);
    }

    .page-content {
      padding: 34px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      background-color: var(--bg-1);

      .title {
        height: 36px;
        width: 40%;
        background-color: var(--bg-2);
        font-weight: 700;
      }

      .desc {
        height: 16px;
        width: 70%;
        background-color: var(--bg-2);
        margin-top: 16px;
      }

      .next-wrapper {
        display: flex;
        gap: 16px;
        align-items: center;
        margin-top: 41px;
        &:hover {
          .next {
            text-decoration: underline;
          }
        }

        svg {
          fill: var(--bg-2) !important;
        }

        .next {
          background-color: var(--bg-2);
          font-weight: 700;
          height: 16px;
          width: 100px;
        }
      }
    }
  }

  .article-card {
    display: flex;
    border-radius: 12px;
    background-color: var(--bg-1);
    overflow: hidden;
    
    &:hover {
      .title {
        text-decoration: underline;
      }
    }

    .img {
      width: 180px;
      height: 180px;
      flex-shrink: 0;
      background-color: var(--bg-2);
    }

    .article-content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 16px 14px;

      .datetime {
        display: flex;
        gap: 8px;
        height: 12px;
        width: 100px;
        background-color: var(--bg-2);
        align-items: center;
      }

      .title {
        font-weight: 700;
        height: 14px;
        margin-top: 8px;
        background-color: var(--bg-2);
        width: 200px;
      }
    }
  }

  .page {
    // Empty selector, can be removed if not needed
  }
}