.carousel_header {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  .slide-rail {
    display: flex;
    height: 100%;
    scroll-snap-type: x mandatory;
    transition: all 0.3s ease-in-out;
  }

  .slide {
    @extend .my-container;
    height: 100vh;
    min-width: 100%;
    max-width: 100%;
    scroll-snap-align: start;
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;

    .content {
      text-align: center;
      color: white;

      .title {
        font-weight: 700;
        font-size: 48px;
        text-transform: capitalize;
      }

      .description {
        max-width: 800px;
        margin: 0 auto;
        margin-top: 5px;
      }

      .subtitle {
        padding: 8px 16px;
        border-radius: 32px;
        border: 1px solid white;
        font-weight: 500;
        font-size: 20px;
        display: block;
        width: fit-content;
        margin: 0 auto 16px;
        text-transform: capitalize;
      }
    }

    .background {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      filter: brightness(0.7);
    }
  }

  .navigator {
    @extend .my-container;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 1;

    .carousel_header-button {
      background-color: rgba(0, 0, 0, 0.15);
      width: 48px;
      height: 48px;
      border-radius: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .bottom-item {
    position: absolute;
    bottom: 55px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    .indicator-rail {
      display: flex;
      gap: 8px;
      margin-bottom: 34px;
  
      .indicator {
        background: white;
        border-radius: 10px;
        width: 10px;
        height: 10px;
        opacity: 0.35;

        &.active {
          opacity: 1;
        }
      }
    }

    .bubble {
      background-color: white;
      width: 64px;
      height: 64px;
      border-radius: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.carousel_header-shadow {
  &.root {
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-size: cover;
    position: relative;
    background-color: var(--bg-1);
  }

  .indicator {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    bottom: 130px;
    transform: translate(-50%, 0);
    gap: 4px;

    div {
      border-radius: 10px;
      width: 10px;
      height: 10px;
      background-color: var(--bg-2);
    }
  }

  .action-button {
    @extend .my-container;
    
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      width: 48px;
      height: 48px;
      border-radius: 48px;
      background-color: var(--bg-2);
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        path {
          fill: var(--bg-3);
        }
      }
    }
  }
  
  .content {
    height: 100%;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    .subtitle {
      border: 1px solid var(--bg-2);
      padding: 8px 16px;
      border-radius: 100px;

      div {
        background-color: var(--bg-2);
        border-radius: 5px;
        width: 100px;
        height: 20px;
      }
    }

    .title {
      font-weight: 700;
      max-width: 700px;
      width: 100%;
      background-color: var(--bg-2);
      height: 48px;
      border-radius: 5px;
    }
  }

  .bubble {
    position: absolute;
    bottom: 55px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: var(--bg-2);
    width: 64px;
    height: 64px;
    border-radius: 64px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      path {
        fill: var(--bg-3);
      }
    }
  }
}