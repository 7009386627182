.long-text-shadow {
  &.root {
    background-color: var(--bg-1);
    padding: 54px 16px;
  }

  .content {
    background-color: var(--bg-2);
    border-radius: 24px;
    padding: 34px;
    text-align: center;
    min-height: 660px;
    display: flex;
    justify-content: center;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    flex-direction: column;
    
    .text {
      @apply rounded-md;
      margin-bottom: 16px;
      height: 24px;
      width: 100%;
      background-color: var(--bg-3);
    }
  }
}

.long-text {
  &.root {
    @extend .my-container;

    padding: 54px 16px;
  }

  .content {
    background-color: white;
    border-radius: 24px;
    padding: 34px;
    text-align: center;
    min-height: 660px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: #212121;
  }
}