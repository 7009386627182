.logo-collection-shadow {
  &.root {
    background-color: var(--bg-1);
    padding: 54px 16px;
  }

  .main-title {
    @apply rounded-md;
    height: 36px;
    background-color: var(--bg-2);
    line-height: 43.2px;
    max-width: 300px;
    width: 100%;
    margin: 0 auto 34px auto;
  }
  
  .row {
    display: flex;
    gap: 34px;
    flex-direction: column;

    @include breakpoint(lg) {
      flex-direction: row;
    }
  }

  .card {
    background-color: var(--bg-2);
    flex: 1;
    border-radius: 24px;

    .image-wrapper {
      padding: 16px;
      border-radius: 24px;
    }

    .preview-image {
      height: 260px;
      border-radius: 24px;
      width: 100%;
      background-color: var(--bg-3);
      object-fit: contain;
    }

    .content {
      padding: 54px 34px;
    }

    .title {
      @apply rounded-md;
      height: 24px;
      max-width: 300px;
      width: 100%;
      margin: 0 auto;
      background-color: var(--bg-3);
      line-height: 29.05px;
    }

    .navigator {
      display: flex;
      gap: 24px;
      justify-content: center;
      margin-top: 34px;
    }
    
    .button {
      width: 150px;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      line-height: 24.2px;
      text-align: center;
      border-radius: 100px;
      border: 1px solid #999;
      background-color: var(--bg-3);
      padding: 16px;

      &.active {
        background-color: #DB1A22;
        font-size: 20px;
        font-weight: 700;
        line-height: 24.2px;
        text-align: center;
        color: white;
      }
    }
  }
}

.logo-collection {
  &.root {
    @extend .my-container;
    
    padding: 54px 16px;
  }

  .main-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 43.2px;
    text-align: center;
    margin-bottom: 34px;
  }
  
  .row {
    display: flex;
    gap: 34px;
    flex-direction: column;

    @include breakpoint(lg) {
      flex-direction: row;
    }
  }

  .card {
    background-color: white;
    flex: 1;
    border-radius: 24px;

    .image-wrapper {
      padding: 16px;
      background-color: #f5f5f5;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
    }

    .preview-image {
      height: 260px;
      border-radius: 24px;
      width: 100%;
      object-fit: contain;
    }

    .content {
      padding: 54px 34px;
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 29.05px;
      text-align: center;
    }

    .navigator {
      display: flex;
      gap: 24px;
      justify-content: center;
      margin-top: 34px;
    }
    
    .button {
      width: 150px;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      line-height: 24.2px;
      text-align: center;
      border-radius: 100px;
      border: 1px solid #999;
      color: #999;
      padding: 16px;

      &.active {
        background-color: #DB1A22;
        font-size: 20px;
        font-weight: 700;
        line-height: 24.2px;
        text-align: center;
        color: white;
      }
    }
  }
}