.tata-kelola-shadow.root {
  @extend .my-container;
  position: relative;
  padding: 54px 0px;
  
  .slider-rail {
    display: flex;
    gap: 34px;
    align-items: stretch;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  .slider-card {
    width: 327px;
    flex-shrink: 0;
    scroll-snap-align: start;
    
    .image {
      height: 330px;
      margin-bottom: -20px;
      border-start-start-radius: 24px;
      border-start-end-radius: 24px;
      background-color: var(--bg-1);
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 260px;
        margin-top: -10px;
        object-fit: contain;
      }
    }

    .ct {
      background-color: var(--bg-2);
      border-radius: 24px;
      padding: 34px;

      .subtitle {
        background-color: var(--bg-3);
        height: 14px;
        width: 40%;
        font-weight: 500;
        border-radius: 5px;
        display: block;
        margin-bottom: 8px;
      }
      .title {
        padding: 8px 0px;
        height: 24px;
        width: 100%;
        margin-bottom: 8px;
        border-radius: 5px;
        background-color: var(--bg-3);
        font-weight: 700;
        overflow: hidden;
      }
      .text > div:not(:first-child) {
        margin-top: 5px;
      }
      .text .desc {
        height: 14px;
        border-radius: 5px;
        background-color: var(--bg-3);
        font-weight: 400;
        width: 100%;
        overflow: hidden;
      }
      .download-card {
        border-radius: 8px;
        border: 1px solid #DEDEDE;
        margin-top: 37px;
        padding: 16px;
        display: flex;
        gap: 12px;
        align-items: center;

        .ttl {
          height: 14px;
          border-radius: 5px;
          width: 50%;
          font-weight: 400;
          background-color: var(--bg-3);
          margin-bottom: 4px;
          display: block;
        }

        .dnld-btn {
          display: block;
          background-color: var(--bg-3);
          height: 12px;
          width: 150px;
          border-radius: 5px;
          font-weight: 500;
        }
      }
    }
  }

  .slider-navigation {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translate(-25px, -50%);
    width: calc(100% + 20px);
    justify-content: space-between;

    .slider-btn {
      height: 54px;
      width: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      
      &.left {
        background-color: white;
        border: 1px solid #DEDEDE;
        border-radius: 100%;
      }
      &.right {
        background-color: #0664B4;
        border-radius: 100%;
      }
    }
  }
}

.tata-kelola.root {
  @extend .my-container;
  position: relative;
  padding: 54px 0px;
  
  .slider-rail {
    display: flex;
    gap: 34px;
    overflow-x: scroll;
    align-items: stretch;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  .slider-card {
    width: 327px;
    flex-shrink: 0;
    scroll-snap-align: start;
    
    .image {
      height: 330px;
      margin-bottom: -20px;
      border-start-start-radius: 24px;
      border-start-end-radius: 24px;
      background-color: #F8F8F8;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 260px;
        margin-top: -10px;
        object-fit: contain;
      }
    }

    .ct {
      background-color: #FFFFFF;
      border-radius: 24px;
      padding: 34px;

      .subtitle {
        color: #999999;
        font-size: 14px;
        font-weight: 500;
      }
      .title {
        padding: 8px 0px;
        max-height: 100px;
        font-size: 24px;
        color: black;
        font-weight: 700;
        overflow: hidden;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        max-height: 63px;
        overflow: hidden;
      }
      .download-card {
        border-radius: 8px;
        border: 1px solid #DEDEDE;
        margin-top: 37px;
        padding: 16px;
        display: flex;
        gap: 12px;
        align-items: center;

        .ttl {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 4px;
          display: block;
        }

        .dnld-btn {
          display: block;
          color: #DB1A22;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }

  .slider-navigation {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translate(-25px, -50%);
    width: calc(100% + 50px);
    justify-content: space-between;

    .slider-btn {
      height: 54px;
      width: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      
      &.left {
        background-color: white;
        border: 1px solid #DEDEDE;
        border-radius: 100%;
      }
      &.right {
        background-color: #0664B4;
        border-radius: 100%;
      }
    }
  }
}