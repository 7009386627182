.books-grid-section-shadow {
  padding-top: 54px;
  padding-bottom: 54px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.books-grid-section-shadow .grid-section {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 34px;
}

@media (min-width: 1024px) {
  .books-grid-section-shadow .grid-section {
    grid-template-columns: repeat(3, 1fr);
  }
}

.books-grid-section-shadow .section-title {
  background-color: var(--bg-1);
  height: 2rem;
  width: 16rem;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.375rem;
}

.books-grid-section-shadow .card {
  background-color: var(--bg-1);
  border-radius: 1.5rem;
  overflow: hidden;
}

.books-grid-section-shadow .card .image {
  height: 370px;
  width: 100%;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  background-color: var(--bg-2);
}

.books-grid-section-shadow .card .content {
  background-color: var(--bg-1);
  margin-top: -20px;
  padding: 34px;
  position: relative;
  z-index: 10;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.books-grid-section-shadow .card .content .date {
  background-color: var(--bg-2);
  height: 1rem;
  width: 8rem;
  border-radius: 0.125rem;
}

.books-grid-section-shadow .card .content .title {
  border-radius: 0.375rem;
  height: 26px;
  width: 90%;
  background-color: var(--bg-2);
  text-decoration: underline;
}

.books-grid-section-shadow .card .content .headline {
  border-radius: 0.375rem;
  height: 16px;
  background-color: var(--bg-2);
  overflow: auto;
}

.books-grid-section {
  padding-top: 54px;
  padding-bottom: 54px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.books-grid-section .grid-section {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 34px;
}

@media (min-width: 1024px) {
  .books-grid-section .grid-section {
    grid-template-columns: repeat(3, 1fr);
  }
}

.books-grid-section .section-title {
  font-size: 36px;
  color: #212121;
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
}

.books-grid-section .card {
  background-color: #F8F8F8;
  border-radius: 1.5rem;
  overflow: hidden;
}

.books-grid-section .card .image-wrapper {
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.books-grid-section .card .image-wrapper .image {
  height: 280px;
  object-fit: contain;
  border-radius: 0.375rem;
}

.books-grid-section .card .content {
  background-color: white;
  padding: 34px;
  position: relative;
  z-index: 10;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.books-grid-section .card .content .date {
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  gap: 8px;
  align-items: center;
}

.books-grid-section .card .content .date .separator {
  height: 4px;
  width: 4px;
  border-radius: 4px;
  background-color: #999999;
  display: block;
}

.books-grid-section .card .content .title {
  font-size: 24px;
  font-weight: 700;
  color: #212121;
  text-decoration: underline;
}

.books-grid-section .card .content .headline {
  font-size: 14px;
  color: #212121;
  height: 105px;
  overflow: auto;
}
