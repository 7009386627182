.card1.card1-2x3-shadow {
  &.root {
    @extend .my-container;

    padding: 54px 15px;
  }

  .title {
    border-radius: 5px;
    height: 24px;
    width: 100%;
    max-width: 300px;
    font-weight: 700;
    line-height: 43.2px;
    background-color: var(--bg-1);
    margin: 0 auto;
    margin-bottom: 24px;
  }

  .description {
    border-radius: 5px;
    height: 16px;
    width: 100%;
    line-height: 24px;
    text-align: center;
    margin-bottom: 8px;
    background-color: var(--bg-1);
  }
  
  .row {
    margin-top: 24px;
    display: flex;
    gap: 34px;
    flex-direction: column;
    
    @include breakpoint(lg) {
      flex-direction: row;
    }

    .card {
      border-radius: 24px;
      overflow: hidden;
      flex: 1;
      background-color: var(--bg-1);
      height: 100%;
      
      .image {
        height: 206px;
        width: 100%;
        object-fit: cover;
        background-color: var(--bg-2);
      }

      .content {
        padding: 34px;

        .card-title {
          border-radius: 5px;
          height: 24px;
          width: 80%;
          text-align: left;
          background-color: var(--bg-2);
          margin-bottom: 8px;
        }

        .card-description {
          border-radius: 5px;
          height: 16px;
          width: 100%;
          background-color: var(--bg-2);
        }
      }
    }
  }

}

.card1 {
  &.root {
    @extend .my-container;

    padding: 54px 15px;
  }

  .title {
    font-size: 36px;
    font-weight: 700;
    line-height: 43.2px;
    text-align: center;
    margin-bottom: 24px;
  }

  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
  
  .row {
    margin-top: 24px;
    display: flex;
    gap: 34px;
    flex-direction: column;
    
    @include breakpoint(lg) {
      flex-direction: row;
    }

    .card {
      border-radius: 24px;
      overflow: hidden;
      flex: 1;
      background-color: white;
      height: 100%;
      
      &:hover .image {
        transform: scale(1.25);
      }
      
      .image {
        position: relative;
        transition: all .3s ease-in-out;
        height: 206px;
        width: 100%;
        object-fit: cover;
      }

      .content {
        padding: 34px;
        position: relative;
        background-color: white;

        .card-title {
          font-size: 24px;
          font-weight: 700;
          line-height: 29.05px;
          text-align: left;
          color: var(--primary-color);
          margin-bottom: 8px;
        }

        .card-description {
          font-size: 24px;
          font-weight: 700;
          line-height: 29.05px;
          text-align: left;
        }
      }
    }
  }

}