.card-slider-right .root {
  /* overflow: hidden; */
}

.card-slider-right .content-container {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  padding-top: 34px;
  padding-bottom: 34px;
  padding-left: 16px;
  padding-right: 16px;
  gap: 24px;
  flex-direction: column;
}

@media (min-width: 768px) {
  .card-slider-right .content-container {
    padding-top: 84px;
    padding-bottom: 84px;
  }
}

@media (min-width: 1024px) {
  .card-slider-right .content-container {
    gap: 34px;
    flex-direction: column;
  }
}

.card-slider-right .left-content {
  gap: 34px;
}

@media (min-width: 1024px) {
  .card-slider-right .left-content {
    display: flex;
    flex-direction: row;
  }
}

.card-slider-right .left-content .card {
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 24px;
  padding: 24px;
  color: white;
}

@media (min-width: 1024px) {
  .card-slider-right .left-content .card {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.card-slider-right .left-content .card .title {
  font-size: 36px;
  font-weight: 700;
}

.card-slider-right .left-content .card .description {
  font-size: 24px;
}

.card-slider-right .right-content .card {
  background-color: white;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 54px;
  overflow: hidden;
}

.card-slider-right .right-content .card img {
  max-height: 938px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  object-fit: contain;
  border-radius: 24px;
}

.card-slider-right-shadow .root {
  background-color: var(--bg-1);
}

.card-slider-right-shadow .content-container {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  padding-top: 34px;
  padding-bottom: 34px;
  padding-left: 16px;
  padding-right: 16px;
  gap: 24px;
  flex-direction: column;
}

@media (min-width: 768px) {
  .card-slider-right-shadow .content-container {
    padding-top: 84px;
    padding-bottom: 84px;
  }
}

@media (min-width: 1024px) {
  .card-slider-right-shadow .content-container {
    gap: 34px;
    flex-direction: column;
  }
}

.card-slider-right-shadow .left-content {
  flex: 0 0 50%;
  gap: 34px;
}

@media (min-width: 1024px) {
  .card-slider-right-shadow .left-content {
    display: flex;
    flex-direction: row;
  }
}

.card-slider-right-shadow .left-content .card {
  background-color: var(--bg-2);
  border-radius: 24px;
  padding: 24px;
  color: white;
}

@media (min-width: 1024px) {
  .card-slider-right-shadow .left-content .card {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.card-slider-right-shadow .left-content .card .title {
  font-size: 36px;
  font-weight: 700;
  height: 36px;
  width: 50%;
  background-color: var(--bg-3);
  margin-bottom: 16px;
  border-radius: 5px;
}

.card-slider-right-shadow .left-content .card .description {
  font-size: 24px;
  height: 24px;
  width: 80%;
  background-color: var(--bg-3);
  border-radius: 5px;
  margin-bottom: 8px;
}

.card-slider-right-shadow .left-content .card .description:last-child {
  width: 40%;
  margin-bottom: 0px;
}

.card-slider-right-shadow .right-content {
  flex: 0 0 50%;
  overflow: hidden;
}

.card-slider-right-shadow .right-content .card {
  padding: 34px;
  background-color: var(--bg-2);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 54px;
  height: 200px;
  overflow: hidden;
  flex: 0 0 50%;
}

.card-slider-right-shadow .right-content .card .title {
  height: 36px;
  width: 50%;
  border-radius: 5px;
  background-color: var(--bg-3);
  font-weight: 700;
  line-height: 43.57px;
  text-align: left;
}

.card-slider-right-shadow .right-content .card .slider-rail {
  display: flex;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.card-slider-right-shadow .right-content .card .slider-card {
  width: 100%;
  flex: 0 0 auto;
  margin-right: 34px;
}

.card-slider-right-shadow .right-content .card .slider-card .slider-image {
  width: 150px;
  height: 150px;
  object-fit: contain;
  background-color: var(--bg-3);
  border-radius: 5px;
}

.card-slider-right-shadow .right-content .card .slider-card .slider-title {
  background-color: var(--bg-3);
  margin-bottom: 16px;
  height: 36px;
  width: 30%;
  border-radius: 5px;
  font-weight: 700;
  margin-top: 8px;
}

.card-slider-right-shadow .right-content .card .slider-card .slider-description {
  background-color: var(--bg-3);
  height: 16px;
  width: 80%;
  border-radius: 5px;
}

.card-slider-right-shadow .right-content .card .slider-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-slider-right-shadow .right-content .card .slider-footer .indicator-list {
  display: flex;
  gap: 8px;
}

.card-slider-right-shadow .right-content .card .slider-footer .indicator-list .indicator {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: var(--bg-3);
  opacity: 0.35;
}

.card-slider-right-shadow .right-content .card .slider-footer .indicator-list .indicator.active {
  opacity: 1;
}

.card-slider-right-shadow .right-content .card .slider-footer .slider-actions {
  display: flex;
  gap: 16px;
}

.card-slider-right-shadow .right-content .card .slider-footer .slider-actions button {
  height: 54px;
  width: 54px;
  border: 1px solid #999999;
  border-radius: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-slider-right-shadow .right-content .card .slider-footer .slider-actions button svg path {
  fill: #999999;
}
