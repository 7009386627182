.card2x2 {
  &.root {
    background-color: #F2F9FF;
    padding: 84px 0;
  }

  .cols {
    @extend .my-container;
    display: flex;
    gap: 34px;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }

    .card {
      border-radius: 24px;
      overflow: hidden;
      color: white;
      position: relative;
      height: 600px;
      padding: 34px;
      flex: 1;

      &:hover .background {
        transform: scale(1.25);
      }
    
      .background {
        transition: all .3s ease-in-out;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 0;
        filter: brightness(.7);
      }

      .content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;

        .title {
          font-weight: 700;
          font-size: 36px;
          margin-bottom: 16px;
        }

        .description {
          margin-bottom: 34px;
          height: 120px;
          overflow: hidden;
        }

        .action {
          display: flex;
          align-items: center;
          gap: 8px;
          width: fit-content;

          svg {
            transition: all .3s ease-in-out;
          }
          
          &:hover svg {
            transform: translate(16px, 0);
          }
        }
      }
    }
  }
}

.card2x2-shadow {
  &.root {
    background-color: var(--bg-1);
    padding: 84px 0;
  }

  .cols {
    @extend .my-container;
    display: flex;
    gap: 34px;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }

    .card {
      border-radius: 24px;
      overflow: hidden;
      background-color: var(--bg-2);
      position: relative;
      height: 600px;
      padding: 34px;
      flex: 1;
    
      .background {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 0;
      }

      .content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;

        .title {
          font-weight: 700;
          width: 100%;
          max-width: 400px;
          background-color: var(--bg-3);
          border-radius: 5px;
          height: 36px;
          margin-bottom: 16px;
        }

        .description {
          height: 120px;
          margin-bottom: 5px;
          overflow: hidden;
          width: 100%;
          max-width: 500px;
          background-color: var(--bg-3);
          border-radius: 5px;
          height: 16px;

          &.last {
            margin-bottom: 29px;
          }
        }

        .action {
          display: flex;
          align-items: center;
          gap: 8px;
          width: fit-content;

          .text {
            width: 150px;
            height: 16px;
            background-color: var(--bg-3);
            border-radius: 5px;
          }

          svg {
            transition: all .3s ease-in-out;

            path {
              fill: var(--bg-3);
            }
          }
          
          &:hover svg {
            transform: translate(16px, 0);
          }
        }
      }
    }
  }
}