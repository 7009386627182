.latest-article {
  &.root {
    @extend .my-container;
    
    padding: 54px 16px;
  }

  .title {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 24px;
  }
  
  .row {
    display: flex;
    gap: 34.5px;
    flex-direction: column;

    @include breakpoint(lg) {
      flex-direction: row;
    }

    .card {
      border-radius: 24px;
      overflow: hidden;
      height: 593px;
      position: relative;
      flex: 1;

      img.image {
        position: absolute;
        z-index: -1;
        border-radius: 24px;
        height: 100%;
        width: 100%;
        // filter: brightness(80%);
        object-fit: cover;
      }

      .content {
        padding: 34px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: left;
        
        .card-title {
          font-size: 24px;
          font-weight: 700;
          color: white;
          margin-bottom: 16px;
        }
  
        .description {
          font-size: 14px;
          color: white;
          margin-bottom: 34px;
        }
  
        .hyperlink {
          font-weight: 700;
          display: flex;
          gap: 8px;
          align-items: center;
          color: white;
          font-size: 16px;
          cursor: pointer;
  
          svg {
            transition: all .3s ease-in-out;
          }
          
          &:hover {
            svg {
              transform: translate(16px, 0);
            }
          }
        }
      }
    }
  }
}