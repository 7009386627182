.stacked-card-shadow {
  &.root {
    background-color: var(--bg-1);
    padding: 54px 0;
  }

  .content {
    @extend .my-container;

    .main-description {
      border-radius: 0.375rem;
      margin: 0 auto 24px auto;
      height: 16px;
      width: 100%;
      background-color: var(--bg-2);
    }

    .cards {
      display: flex;
      gap: 54px;
      flex-direction: column;
    }
    
    .card {
      display: flex;
      flex-direction: column;

      @include breakpoint(lg) {
        flex-direction: row;
      }

      height: 600px;
      border: 1px solid #DEDEDE;
      border-radius: 24px;

      &:hover .image {
        transform: scale(1.25);
      }

      &.reverse {
        display: flex;
        flex-direction: column;

        @include breakpoint(lg) {
          flex-direction: row-reverse;
        }

        .image {
          border-radius: 24px 24px 0 0;
          
          @include breakpoint(lg) {
            border-radius: 0 24px 24px 0;
          }
        }
      }

      .image {
        background-color: var(--bg-2);
        height: 328px;
        border-radius: 24px 24px 0 0;
        width: 100%;
        flex-shrink: 0;
        object-fit: cover;
        transition: all .3s ease-in-out;
        
        @include breakpoint(lg) {
          width: 428px;
          height: 600px;
          border-radius: 24px 0 0 24px;
        };
        
        @include breakpoint(xl) {
          width: 558px;
        };
      }

      .description {
        border-radius: 0.375rem;
        width: 100%;
        height: 16px;
        margin-bottom: 8px;
        background-color: var(--bg-2);

        @include breakpoint(lg) {
          overflow: auto;
        }
      }
    }
  }

}

.stacked-card {
  &.root {
    background-color: white;
    padding: 54px 0;
  }

  .content {
    @extend .my-container;

    .main-description {
      text-align: center;
      margin-bottom: 24px;
      font-size: 16px;
    }

    .cards {
      display: flex;
      gap: 54px;
      flex-direction: column;
    }
    
    .card {
      display: flex;
      flex-direction: column;

      @include breakpoint(lg) {
        flex-direction: row;
      }
      
      border: 1px solid #DEDEDE;
      border-radius: 24px;

      &.reverse {
        display: flex;
        flex-direction: column;

        @include breakpoint(lg) {
          flex-direction: row-reverse;
        }

        .image {
          border-radius: 24px 24px 0 0;
          
          @include breakpoint(lg) {
            border-radius: 0 24px 24px 0;
          }
        }
      }

      .image {
        height: 328px;
        border-radius: 24px 24px 0 0;
        width: 100%;
        flex-shrink: 0;
        object-fit: cover;
        
        @include breakpoint(lg) {
          width: 428px;
          height: 600px;
          border-radius: 24px 0 0 24px;
        };
        
        @include breakpoint(xl) {
          width: 558px;
        };
      }

      .description {
        padding: 34px;
        font-size: 14px;

        @include breakpoint(lg) {
          height: 600px;
          overflow: auto;
        }
      }
    }
  }

}