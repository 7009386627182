.contact-card-shadow {
  &.root {
    background-color: var(--bg-1);
    padding: 54px 16px;
  }

  .row {
    display: flex;
    gap: 34px;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  .card {
    flex: 1;
    background-color: var(--bg-2);
    border-radius: 24px;
    overflow: hidden;

    .card-content {
      padding: 34px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: max-content;
      flex: 1 0;
    }

    .card-image {
      height: 300px;
      object-fit: cover;
      width: 100%;
    }

    .card-title {
      border-radius: 4px;
      height: 36px;
      width: 80%;
      background-color: var(--bg-3);
      line-height: 43.57px;
      margin-bottom: 12px;
    }

    .card-description {
      border-radius: 4px;
      height: 24px;
      background-color: var(--bg-3);
      width: 90%;
      line-height: 36px;
      margin-bottom: 63px;
    }

    .card-action {
      width: 100%;
      padding: 25px;
      border-radius: 8px;
      background-color: var(--bg-3);
      font-size: 16px;
      font-weight: 700;
      line-height: 19.36px;
      text-align: center;
      display: block;
    }
  }
}

.contact-card {
  &.root {
    @extend .my-container;
    padding: 54px 16px;
  }

  .row {
    display: flex;
    gap: 34px;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  .card {
    flex: 1;
    background-color: white;
    border-radius: 24px;
    overflow: hidden;

    &:hover .card-image {
      transform: scale(1.25);
    }
    
    .card-content {
      padding: 34px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: max-content;
      flex: 1 0;
    }
    
    .card-image {
      transition: all .3s ease-in-out;
      height: 300px;
      object-fit: cover;
      width: 100%;
    }

    .card-title {
      font-size: 36px;
      font-weight: 700;
      line-height: 43.57px;
      text-align: left;
      margin-bottom: 12px;
    }

    .card-description {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      text-align: left;
      margin-bottom: 63px;
    }

    .card-action {
      width: 100%;
      padding: 25px;
      border-radius: 8px;
      background-color: var(--primary-color);
      color: white;
      font-size: 16px;
      font-weight: 700;
      line-height: 19.36px;
      text-align: center;
      display: block;
    }
  }
}