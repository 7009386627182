.frame-video-shadow {
  &.root {
    background-color: var(--bg-1);
    padding: 54px 16px;
  }

  .title {
    border-radius: 4px;
    height: 36px;
    max-width: 300px;
    background-color: var(--bg-2);
    width: 100%;
    margin: 0 auto 24px auto;
  }

  .description {
    border-radius: 4px;
    height: 16px;
    width: 90%;
    background-color: var(--bg-2);
    margin: 0 auto 24px auto;
  }

  .video {
    width: 100%;
    margin: 0 auto;
    max-width: 882px;
    height: 497px;
    background-color: var(--bg-2);
    border-radius: 24px;
  }
}

.frame-video {
  &.root {
    @extend .my-container;
    padding: 54px 16px;
  }

  .title {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 24px;
  }

  .description {
    font-size: 16px;
    text-align: center;
    margin-bottom: 24px;
  }

  .video {
    width: 100%;
    margin: 0 auto;
    max-width: 882px;
    height: 100%;
    max-height: 497px;
    background-color: #fff;
    border-radius: 24px;
  }
}
