.image-description-shadow {
  &.root {
    background-color: var(--bg-1);
    padding: 54px 16px;
  }
  
  &.reverse {
    .card {
      @include breakpoint(lg) {
        flex-direction: row-reverse;
      }
    }
    .title {
      margin: 0 0 34px auto !important;
    }

    .description {
      margin: 0 0 14px auto !important;
    }
  }

  .card {
    border-radius: 24px;
    display: flex;
    background-color: var(--bg-2);
    overflow: hidden;
    border: 1px solid #DEDEDE;
    flex-direction: column;

    @include breakpoint(lg) {
      height: 600px;
      flex-direction: row;
    }

    .image {
      object-fit: cover;
      background-color: var(--bg-3);
      height: 328px;
      width: 100%;
      
      @include breakpoint(lg) {
        height: 600px;
        width: 428px;
      }
    }

    .content {
      padding: 34px;
      flex: 1;

      @include breakpoint(lg) {
        overflow: auto;
      }

      .title {
        @apply rounded-md;
        height: 36px;
        margin: 0 auto 34px 0;
        width: 300px;
        background-color: var(--bg-3);
      }

      .description {
        @apply rounded-md;
        height: 14px;
        width: 90%;
        margin: 0 auto 14px 0;
        background-color: var(--bg-3);
      }
    }
  }
}

.image-description {
  &.root {
    @extend .my-container;

    padding: 54px 16px;
  }
  
  &.reverse {
    .card {
      @include breakpoint(lg) {
        flex-direction: row-reverse;
      }
    }
  }

  .card {
    border-radius: 24px;
    display: flex;
    background-color: white;
    overflow: hidden;
    border: 1px solid #DEDEDE;
    flex-direction: column;

    @include breakpoint(lg) {
      height: 600px;
      flex-direction: row;
    }
    
    &:hover .image {
      transform: scale(1.25);
    }

    .image {
      object-fit: cover;
      transition: all .3s ease-in-out;
      height: 328px;
      width: 100%;
      
      @include breakpoint(lg) {
        height: 600px;
        width: 428px;
      }
    }

    .content {
      padding: 34px;

      @include breakpoint(lg) {
        overflow: auto;
      }

      .title {
        font-size: 36px;
        color: #212121;
        font-weight: 700;
        margin-bottom: 24px;
      }

      .description {
        font-size: 14px;
        color: #212121;
      }
    }
  }
}