@tailwind base;
@tailwind components;
@tailwind utilities;

$primary-color: #0664B4;
$secondary-color: #F2F9FF;
$text-color: #212121;

:root {
  --primary-color: #0664B4;
  --secondary-color: #F2F9FF;
  --text-color: #212121;
  --bg-1: #e5e7eb;
  --bg-2: #d1d5db;
  --bg-3: #9ca3af;
  --bg-4: #6b7280;
}

body {
  // background-color: var(--secondary-color);
  // font-size: 16px;
}

@mixin container-mixin {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;

  @media (min-width: 640px) {
    max-width: 640px;
  }

  @media (min-width: 768px) {
    max-width: 768px;
  }

  @media (min-width: 1024px) {
    max-width: 1024px;
  }

  @media (min-width: 1280px) {
    max-width: 1280px;
  }
}

@mixin breakpoint($size) {
  @if $size == sm {
    @media (min-width: 640px) { @content; }
  }
  @else if $size == md {
    @media (min-width: 768px) { @content; }
  }
  @else if $size == lg {
    @media (min-width: 1024px) { @content; }
  }
  @else if $size == xl {
    @media (min-width: 1280px) { @content; }
  }
}

.my-container {
  @include container-mixin;
}

@import "announcement-grid-section";
@import "article-grid-section";
@import "article";
@import "books-grid-section";
@import "card-slider-large";
@import "card-slider-right";
@import "card-slider";
@import "card1";
@import "card1x2-small";
@import "card2";
@import "card2x2";
@import "carousel-header";
@import "contact-card";
@import "frame-video";
@import "full-image-description";
@import "full-image";
@import "header";
@import "image-description";
@import "latest-article-page";
@import "latest-article";
@import "logo-collection";
@import "logo-slider";
@import "long-text";
@import "operational-spot";
@import "stacked-card";
@import "tata-kelola";
@import "team-slider";
@import "timeline";
