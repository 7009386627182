.card-slider-large-shadow {
  &.root {
    background-color: var(--bg-1);
  }

  .container-content {
    @extend .my-container;
    
    display: flex;
    padding: 54px 16px;
    gap: 34px;
    flex-direction: column;

    @include breakpoint(lg) {
      flex-direction: row;
    }
  }

  .content {
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 34px;

    .content-title {
      border-radius: 5px;
      height: 36px;
      background-color: var(--bg-2);
      line-height: 43.57px;
      width: 70%;
    }

    .content-description {
      border-radius: 5px;
      height: 16px;
      background-color: var(--bg-2);
      margin-top: 24px;
      line-height: 24px;
      width: 100%;
    }
  }

  .slider-rail {
    flex: 0 0 70%;
    height: 660px;
    display: flex;
    gap: 34px;
    overflow: hidden;
  }

  .slider-data {
    transition: all .3s ease-in-out;
    width: 100%;
    height: 660px;
    display: flex;
    gap: 34px;
  }
  
  .slider-card {
    background-color: var(--bg-2);
    border-radius: 24px;
    height: 100%;
    display: flex;
    flex: 0 0 100%;
    border: 1px solid #ddd;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }

    .slider-image-wrapper {
      flex: 0 0 40%;
      height: 100%;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      padding: 34px;
      color: white;
      background-size: cover;
      background-position: center;

      @include breakpoint(md) {
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        border-top-right-radius: 0;
      }

      .slider-title {
        font-size: 24px;
        font-weight: 400;
        line-height: 29.05px;
        text-align: left;
      }
      
      .slider-subtitle {
        margin-top: 16px;
        font-size: 36px;
        font-weight: 700;
        line-height: 43.57px;
        text-align: left;
      }
    }

    .slider-content {
      flex: 0 0 60%;
      padding: 34px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      overflow: auto;
    }
  }

  .slider-navigator {
    display: flex;
    justify-content: space-between;

    .slider-button {
      display: flex;
      gap: 16px;
    }

    .button {
      width: 54px;
      height: 54px;
      border-radius: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.left {
        border: 1px solid #ddd;
      }
      &.right {
        background-color: var(--bg-2);
      }
    }
  }

  svg path {
    fill: var(--bg-3)
  }
  
  .slider-indicators {
    display: flex;
    gap: 8px;
    align-items: center;

    div {
      background-color: #212121;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      opacity: .35;

      &.active {
        opacity: 1;
      }
    }
  }
}

.card-slider-large {
  &.root {
    background-color: white;
  }

  .container-content {
    @extend .my-container;
    
    display: flex;
    padding: 54px 16px;
    gap: 34px;
    flex-direction: column;

    @include breakpoint(lg) {
      flex-direction: row;
    }
  }

  .content {
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 34px;

    .content-title {
      font-size: 36px;
      font-weight: 700;
      line-height: 43.57px;
      text-align: left;
    }

    .content-description {
      font-size: 16px;
      margin-top: 24px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
  }

  .slider-rail {
    flex: 0 0 70%;
    height: 660px;
    display: flex;
    gap: 34px;
    overflow: hidden;
  }

  .slider-data {
    transition: all .3s ease-in-out;
    width: 100%;
    height: 660px;
    display: flex;
    gap: 34px;
  }
  
  .slider-card {
    background-color: white;
    border-radius: 24px;
    height: 100%;
    display: flex;
    flex: 0 0 100%;
    border: 1px solid #ddd;
    flex-direction: column;
    overflow: hidden;

    @include breakpoint(md) {
      flex-direction: row;
    }

    &:hover img {
      transform: scale(1.25);
    }

    .slider-image-wrapper {
      flex: 0 0 40%;
      height: 100%;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      padding: 34px;
      color: white;
      background-size: cover;
      background-position: center;
      position: relative;

      @include breakpoint(md) {
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        border-top-right-radius: 0;
      }
      
      img {
        -webkit-filter: brightness(.7);
        -moz-filter: brightness(.7);
        -o-filter: brightness(.7);
        -ms-filter: brightness(.7);
        filter: brightness(.7);
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
        transition: all .3s ease-in-out;
      }

      .slider-title {
        font-size: 24px;
        font-weight: 400;
        line-height: 29.05px;
        text-align: left;
        position: relative;
      }
      
      .slider-subtitle {
        margin-top: 16px;
        font-size: 36px;
        font-weight: 700;
        line-height: 43.57px;
        text-align: left;
        position: relative;
      }
    }

    .slider-content {
      background-color: white;
      position: relative;
      flex: 0 0 60%;
      padding: 34px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      overflow: auto;
    }
  }

  .slider-navigator {
    display: flex;
    justify-content: space-between;

    .slider-button {
      display: flex;
      gap: 16px;
    }

    .button {
      width: 54px;
      height: 54px;
      border-radius: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.left {
        border: 1px solid #ddd;
      }
      &.right {
        background-color: var(--primary-color);
      }
    }
  }
  
  .slider-indicators {
    display: flex;
    gap: 8px;
    align-items: center;

    div {
      background-color: #212121;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      opacity: .35;

      &.active {
        opacity: 1;
      }
    }
  }
}