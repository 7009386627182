.full_image {
  &.root {
    padding: 54px 0;
    background-color: white;
  }

  .content {
    @extend .my-container;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .title {
      font-weight: 700;
      font-size: 36px;
      color: $text-color;
      margin-bottom: 34px;
      text-align: center;
    }

    img {
      max-height: 938px;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      object-fit: contain;
      border-radius: 24px;
    }
  }
}

.full_image-shadow {
  &.root {
    padding: 54px 0;
    background-color: var(--bg-1);
  }

  .content {
    @extend .my-container;

    .title {
      font-weight: 700;
      height: 36px;
      max-width: 500px;
      width: 100%;
      background-color: var(--bg-2);
      text-align: center;
      margin: 0 auto 34px auto;
      border-radius: 5px;
    }

    .image {
      max-height: 938px;
      width: 100%;
      margin: 0 auto;
      height: 588px;
      object-fit: contain;
      border-radius: 24px;
      background-color: var(--bg-2);
    }
  }
}
