.card2-2x2-shadow {
  &.root {
    padding: 54px 0;
    background-color: white;
  }

  .main-title {
    border-radius: 4px;
    height: 36px;
    max-width: 300px;
    width: 100%;
    margin: 0 auto 24px auto;
    line-height: 43.2px;
    background-color: var(--bg-1);
  }

  .main-description {
    border-radius: 4px;
    height: 16px;
    width: 80%;
    margin: 0 auto;
    font-weight: 400;
    line-height: 24px;
    background-color: var(--bg-1);
  }

  .content {
    @extend .my-container;
  }

  .row {
    display: flex;
    gap: 34px;
    margin-top: 24px;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  .card {
    flex: 1;
    padding: 34px;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 283px;
    background-color: var(--bg-1);
    gap: 16px;
  }

  .card-title {
    border-radius: 4px;
    height: 36px;
    background-color: var(--bg-2);
    width: 60%;
    line-height: 43.57px;
  }

  .card-description {
    border-radius: 4px;
    height: 24px;
    width: 90%;
    background-color: var(--bg-2);
    line-height: 36px;
  }
}

.card2-2x2 {
  &.root {
    padding: 54px 0;
    background-color: white;
  }

  .main-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 43.2px;
    text-align: center;
    margin-bottom: 24px;
  }

  .main-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  .content {
    @extend .my-container;
  }

  .row {
    display: flex;
    gap: 34px;
    margin-top: 24px;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  .card {
    flex: 1;
    padding: 34px;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 283px;
    background-color: var(--primary-color);
    color: white;
    gap: 16px;
  }

  .card-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 43.57px;
    text-align: left;
  }

  .card-description {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
  }
}