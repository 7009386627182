.card-slider {
  container: mx-auto;
  padding: 20px;
  padding-top: 54px;
  padding-bottom: 54px;
}

.card-slider .main-title {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 44px;
}

.card-slider .card-slider-rail {
  display: flex;
  gap: 5%;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.card-slider .card-slider-content {
  position: relative;
  overflow: hidden;
}

.card-slider .slider-controller {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  justify-content: space-between;
}

.card-slider .slider-controller button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-slider .slider-controller button.left {
  border-color: #999999;
  background-color: white;
}

.card-slider .slider-controller button.right {
  border-color: #999999;
  background-color: #0664B4;
  border: none;
}

.card-slider .card-slider-card {
  height: 583px;
  width: 30%;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex-shrink: 0;
}

.card-slider .card-slider-card .img {
  position: absolute;
  z-index: -1;
  height: 583px;
  width: 100%;
  object-fit: cover;
}

.card-slider .card-slider-card .card-card-card {
  padding: 34px;
}

.card-slider .card-slider-card .card-number {
  font-size: 48px;
}

.card-slider .card-slider-card .title {
  font-size: 36px;
  font-weight: bold;
}

.card-slider .card-slider-card .desc {
  font-size: 16px;
  margin-top: 8px;
}

.card-slider .card-slider-card .target {
  font-weight: 600;
  font-size: 20px;
  display: flex;
  gap: 24px;
  margin-top: 54px;
  align-items: center;
}

.card-slider-shadow {
  container: mx-auto;
  padding: 20px;
  padding-top: 54px;
  padding-bottom: 54px;
}

.card-slider-shadow .card-slider-rail {
  display: flex;
  gap: 5%;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.card-slider-shadow .card-slider-content {
  position: relative;
  overflow: hidden;
}

.card-slider-shadow .card-slider-card {
  height: 583px;
  width: 30%;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  background-color: var(--bg-1);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex-shrink: 0;
}

.card-slider-shadow .card-slider-card .img {
  position: absolute;
  z-index: -1;
  height: 583px;
  width: 100%;
  object-fit: cover;
}

.card-slider-shadow .card-slider-card .card-card-card {
  padding: 34px;
}

.card-slider-shadow .card-slider-card .card-number {
  height: 48px;
  width: 40px;
  background-color: var(--bg-2);
  border-radius: 4px;
  display: block;
}

.card-slider-shadow .card-slider-card .title {
  height: 36px;
  width: 30%;
  font-weight: bold;
  background-color: var(--bg-2);
  border-radius: 4px;
  margin-top: 8px;
}

.card-slider-shadow .card-slider-card .desc {
  height: 16px;
  width: 60%;
  margin-top: 8px;
  background-color: var(--bg-2);
  border-radius: 4px;
}

.card-slider-shadow .card-slider-card .target {
  font-weight: 600;
  display: flex;
  gap: 24px;
  margin-top: 54px;
  align-items: center;
}

.card-slider-shadow .card-slider-card .target p {
  height: 20px;
  width: 100px;
  background-color: var(--bg-2);
  border-radius: 4px;
  display: block;
}

.card-slider-shadow .card-slider-card .target path {
  fill: var(--bg-2);
}