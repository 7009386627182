.timeline-shadow {
  display: flex;
  @extend .container;
  margin-left: auto;
  margin-right: auto;
  align-items: stretch;
  gap: 24px;
  padding-top: 54px;
  padding-bottom: 54px;

  .timeline-left {
    height: 100%;

    .line {
      width: 1px;
      background-color: #DEDEDE;
      height: 445px;
    }

    .bullet {
      position: absolute;
      margin-left: -8px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background-color: var(--bg-2);

      &.one {
        top: 112px;
      }

      &.two {
        top: 266px;
      }

      &.three {
        top: 421px;
      }
    }
  }

  .timeline-right {
    display: flex;
    flex: 1;
    flex-direction: column;
    row-gap: 24px;

    .timeline-content {
      padding: 44px;
      border-radius: 8px;
      background-color: var(--bg-1);
    }

    .timeline-text {
      height: 44px;
      border-radius: 6px;
      background-color: var(--bg-2);
      width: 6rem;
    }
  }
}